import React from 'react'
import './Loader.css'
import Raven from './imgs/favicon-512x512.png'
import { Typography } from '@material-ui/core'
import ButtonCircularProgress from './shared/components/ButtonCircularProgress'

function Loader() {
  return (
    <div className="loader">
      <img style={{ width: 100 }} src={Raven} alt={'Raven Icon'} />
      <br />
      <ButtonCircularProgress />
      <Typography>Initializing... </Typography>
    </div>
  )
}

export default Loader
