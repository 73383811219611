import {
  LOADING_GET_PATIENT,
  SET_PATIENT,
  GET_PATIENT_ERROR,
  LOADING_GET_PATIENTS,
  SET_PATIENTS,
  GET_PATIENTS_ERROR,
  LOADING_GET_GENDER,
  SET_GENDERS,
  GET_GENDER_ERROR,
  LOADING_GET_TITLE,
  SET_TITLES,
  GET_TITLE_ERROR,
  LOADING_ADD_PATIENT,
  ADD_PATIENT_ERROR,
  ADD_PATIENT,
  LOADING_IMPORT_PATIENT,
  IMPORT_PATIENT_SUCCESS,
  IMPORT_PATIENT_ERRORS,
  LOADING_CHECK_PATIENT,
  CHECK_PATIENT_SUCCESS,
  CHECK_PATIENT_ERROR,
  CLEAR_PATIENT_CSV,
  UPDATE_PATIENT,
  UPDATE_PATIENT_GOS,
  LOADING_UPDATE_PATIENT,
  UPDATE_PATIENT_ERROR,
  UPDATE_APPOINTMENT_PATIENT,
  ADD_APPOINTMENT_PATIENT,
  DELETE_APPOINTMENT_PATIENT,
  DELETE_PATIENT,
  LOADING_DELETE_PATIENT,
  DELETE_PATIENT_ERROR,
  ADD_PRESCIPTION,
  LOADING_ADD_PRESCIPTION,
  ADD_PRESCIPTION_ERROR,
  UPDATE_PRESCIPTION,
  UPDATE_PRESCIPTION_ERROR,
  LOADING_UPDATE_PRESCIPTION,
  DELETE_PRESCIPTION,
  LOADING_DELETE_PRESCIPTION,
  DELETE_PRESCIPTION_ERROR,
  ADD_PATIENT_DISPENSING,
  PAY_PATIENT_DISPENSING,
  DELETE_PATIENT_DISPENSING,
  ADD_TODO_PATIENT,
  DELETE_TODO_PATIENT,
  UPDATE_TODO_PATIENT,
  ADD_RECALL_PATIENT,
  DELETE_RECALL_PATIENT,
  UPDATE_RECALL_PATIENT,
  LOADING_UPLOAD_PATIENT_DOCUMENT,
  UPLOAD_PATIENT_DOCUMENT_ERRORS,
  SET_PATIENT_DOCUMENTS,
  SELECT_ACTIVE_PATIENT,
  ADD_NOTE_PATIENT,
  UPDATE_NOTE_PATIENT,
  DELETE_NOTE_PATIENT,
  ADD_MESSAGE_PATIENT,
  DELETE_MESSAGE_PATIENT,
  ADD_CLINICAL_RECORD_PATIENT,
  DELETE_PATIENT_CLINICAL,
  DELETE_PATIENT_SALE
} from "../types";

const initialState = {
  loading: false,
  gos: {},
  patients: [],
  documents: [],
  active_patient: null,
  patient: {},
  appointments: [],
  recalls: [],
  todos: [],
  sales: [],
  notes: [],
  clinical: [],
  prescriptions: [],
  messages: [],
  spectacles: [],
  errors: null,
  patient_loading: false,
  patient_errors: null,
  next_page_url: null,
  total: 0,
  per_page: 0,
  loading_gender: false,
  get_gender_errors: null,
  genders: [],
  loading_title: false,
  get_title_errors: null,
  titles: [],
  loading_add_patient: false,
  add_patient_errors: null,
  patient_import: [],
  loading_import_patients: false,
  import_patients_errors: null,
  duplicate: [],
  loading_patient_check: false,
  check_patient_errors: null,
  loading_update_patient: false,
  update_patient_error: null,
  loading_delete_patient: false,
  delete_patient_errors: null,
  loading_add_prescription: false,
  add_prescription_errors: null,
  loading_delete_prescription: false,
  delete_prescription_errors: null,
  loading_update_prescription: false,
  update_prescription_errors: null,
  loading_upload_patient_document: false,
  upload_patient_document_errors: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_GET_PATIENTS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case LOADING_DELETE_PATIENT:
      return {
        ...state,
        loading_delete_patient: true,
        delete_patient_errors: null,
      };
    case LOADING_UPLOAD_PATIENT_DOCUMENT:
      return {
        ...state,
        loading_upload_patient_document: true,
        upload_patient_document_errors: null,
      };
    case LOADING_DELETE_PRESCIPTION:
      return {
        ...state,
        loading_delete_prescription: true,
        delete_prescription_errors: null,
      };
    case LOADING_UPDATE_PRESCIPTION:
      return {
        ...state,
        loading_update_prescription: true,
        update_prescription_errors: null,
      };
    case LOADING_ADD_PRESCIPTION:
      return {
        ...state,
        loading_add_prescription: true,
        add_prescription_errors: null,
      };
    case LOADING_UPDATE_PATIENT:
      return {
        ...state,
        loading_update_patient: true,
        update_patient_error: null,
      };
    case LOADING_GET_PATIENT:
      return {
        ...state,
        patient_loading: true,
        patient_errors: null,
      };
    case ADD_APPOINTMENT_PATIENT:
      return {
        ...state,
        appointments: [...state.appointments, action.payload],
      };
    case ADD_CLINICAL_RECORD_PATIENT:
      return {
        ...state,
        clinical: [...state.clinical, action.payload],
      };
    case DELETE_PATIENT_CLINICAL:
      let delClinical = state.clinical.findIndex(
        (x) => x.id === action.payload.id
      );
      return {
        ...state,
        clinical: state.clinical.filter((item, index) => index !== delClinical),
      };
    case ADD_MESSAGE_PATIENT:
      return {
        ...state,
        messages: [...state.messages, ...action.payload],
      };
    case ADD_NOTE_PATIENT:
      return {
        ...state,
        notes: [...state.notes, action.payload],
      };
    case UPDATE_NOTE_PATIENT:
      let NOTE_index = state.notes.findIndex((x) => x.id === action.payload.id);
      const NOTEUpdate = [...state.notes];
      NOTEUpdate[NOTE_index] = action.payload;
      return {
        ...state,
        notes: [...NOTEUpdate],
      };
    case DELETE_NOTE_PATIENT:
      let del_note = state.notes.findIndex((x) => x.id === action.payload.id);
      return {
        ...state,
        notes: state.notes.filter((item, index) => index !== del_note),
      };
    case SELECT_ACTIVE_PATIENT:
      return {
        ...state,
        active_patient: action.payload,
      };
    case ADD_TODO_PATIENT:
      return {
        ...state,
        todos: [...state.todos, action.payload],
      };
    case ADD_RECALL_PATIENT:
      return {
        ...state,
        recalls: [...state.recalls, action.payload],
      };
    case ADD_PRESCIPTION:
      return {
        ...state,
        prescriptions: [...state.prescriptions, action.payload],
        loading_add_prescription: false,
        add_prescription_errors: null,
      };
    case ADD_PATIENT_DISPENSING:
      return {
        ...state,
        spectacles: [...state.spectacles, action.payload],
      };
    case UPDATE_APPOINTMENT_PATIENT:
      let appointment_index = state.appointments.findIndex(
        (x) => x.id === action.payload.id
      );
      const appointmentUpdate = [...state.appointments];
      appointmentUpdate[appointment_index] = action.payload;
      return {
        ...state,
        appointments: [...appointmentUpdate],
      };
    case UPDATE_RECALL_PATIENT:
      let recall_index = state.recalls.findIndex(
        (x) => x.id === action.payload.id
      );
      const recallsUpdate = [...state.recalls];
      recallsUpdate[recall_index] = action.payload;
      return {
        ...state,
        recalls: [...recallsUpdate],
      };
    case UPDATE_TODO_PATIENT:
      let todo_index = state.todos.findIndex((x) => x.id === action.payload.id);
      const todoUpdate = [...state.todos];
      todoUpdate[todo_index] = action.payload;
      return {
        ...state,
        todos: [...todoUpdate],
      };
    case PAY_PATIENT_DISPENSING:
      let pay_patient_index = state.spectacles.findIndex(
        (x) => x.id === action.payload.id
      );
      const payPatientSpectacles = [...state.spectacles];
      payPatientSpectacles[pay_patient_index] = action.payload;
      return {
        ...state,
        spectacles: [...payPatientSpectacles],
      };
    case UPDATE_PRESCIPTION:
      let prescription_index = state.prescriptions.findIndex(
        (x) => x.id === action.payload.id
      );
      const prescriptionUpdate = [...state.prescriptions];
      prescriptionUpdate[prescription_index] = action.payload;
      return {
        ...state,
        prescriptions: [...prescriptionUpdate],
        loading_update_prescription: false,
        update_prescription_errors: null,
      };
    case DELETE_APPOINTMENT_PATIENT:
      let del = state.appointments.findIndex((x) => x.id === action.payload.id);
      return {
        ...state,
        appointments: state.appointments.filter((item, index) => index !== del),
      };
      case DELETE_PATIENT_SALE:
      let delSale = state.sales.findIndex((x) => x.id === action.payload.id)
      return {
        ...state,
        sales: state.sales.filter((item, index) => index !== delSale),
      }
    case DELETE_TODO_PATIENT:
      let del_todo = state.todos.findIndex((x) => x.id === action.payload.id);
      return {
        ...state,
        todos: state.todos.filter((item, index) => index !== del_todo),
      };
    case DELETE_RECALL_PATIENT:
      let del_recall = state.recalls.findIndex(
        (x) => x.id === action.payload.id
      );
      return {
        ...state,
        recalls: state.recalls.filter((item, index) => index !== del_recall),
      };
    case SET_PATIENTS:
      return {
        ...state,
        patients: action.payload.data,
        next_page_url: action.payload.next_page_url,
        total: action.payload.total,
        per_page: action.payload.per_page,
        loading: false,
        error: null,
      };
    case SET_PATIENT:
      return {
        ...state,
        patient: action.payload,
        appointments: action.payload.appointment,
        recalls: action.payload.recall,
        documents: action.payload.document,
        todos: action.payload.todo,
        gos: action.payload.gos,
        prescriptions: action.payload.prescription,
        messages: action.payload.messages,
        spectacles: action.payload.spectacles,
        sales: action.payload.sale,
        notes: action.payload.note,
        clinical: action.payload.clinical,
        patient_loading: false,
        patient_errors: null,
        update_patient_error: null,
      };
    case SET_PATIENT_DOCUMENTS:
      return {
        ...state,
        documents: action.payload,
        loading_upload_patient_document: false,
        upload_patient_document_errors: null,
      };
    case UPDATE_PATIENT:
      let patient_index = state.patients.findIndex(
        (x) => x.id === action.payload.id
      );
      const updatedPatients = [...state.patients];
      updatedPatients[patient_index] = action.payload;
      return {
        ...state,
        patient: action.payload,
        patients: [...updatedPatients],
        loading_update_patient: false,
        update_patient_error: null,
      };
    case UPDATE_PATIENT_GOS:
      return {
        ...state,
        gos: action.payload,
        loading_update_patient: false,
        update_patient_error: null,
      };
    case GET_PATIENTS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_PATIENT_ERROR:
      return {
        ...state,
        patient_loading: false,
        patient_errors: action.payload,
      };
    case LOADING_GET_GENDER:
      return {
        ...state,
        loading_gender: true,
        get_gender_errors: null,
      };
    case SET_GENDERS:
      return {
        ...state,
        genders: action.payload.genders,
        loading_gender: false,
        get_gender_errors: null,
      };
    case GET_GENDER_ERROR:
      return {
        ...state,
        loading_gender: false,
        get_gender_errors: action.payload,
      };
    case LOADING_GET_TITLE:
      return {
        ...state,
        loading_title: true,
        get_title_errors: null,
      };
    case SET_TITLES:
      return {
        ...state,
        titles: action.payload.titles,
        loading_title: false,
        get_title_errors: null,
      };
    case GET_TITLE_ERROR:
      return {
        ...state,
        loading_title: false,
        get_title_errors: action.payload,
      };
    case ADD_PATIENT:
      let ttl = state.total + 1;
      return {
        ...state,
        patients: [...state.patients, action.payload],
        total: ttl,
        add_patient_errors: null,
        loading_add_patient: false,
      };
    case LOADING_ADD_PATIENT:
      return {
        ...state,
        add_patient_errors: null,
        loading_add_patient: true,
      };
    case ADD_PATIENT_ERROR:
      return {
        ...state,
        add_patient_errors: action.payload.errors,
        loading_add_patient: false,
      };
    case CLEAR_PATIENT_CSV:
      return {
        ...state,
        patient_import: [],
        loading_import_patients: false,
        import_patients_errors: null,
        duplicate: [],
        loading_patient_check: false,
        check_patient_errors: null,
      };
    case DELETE_PATIENT:
      let del_index = state.patients.findIndex(
        (x) => x.id === action.payload.id
      );
      return {
        ...state,
        patients: state.patients.filter((item, index) => index !== del_index),
        loading_delete_patient: false,
        delete_patient_errors: null,
      };
    case DELETE_PRESCIPTION:
      let pres_index = state.prescriptions.findIndex(
        (x) => x.id === action.payload.id
      );
      return {
        ...state,
        prescriptions: state.prescriptions.filter(
          (item, index) => index !== pres_index
        ),
        loading_delete_prescription: false,
        delete_prescription_errors: null,
      };
    case DELETE_PATIENT_DISPENSING:
      let del_dispense_index = state.spectacles.findIndex(
        (x) => x.id === action.payload.id
      );
      return {
        ...state,
        spectacles: state.spectacles.filter(
          (item, index) => index !== del_dispense_index
        ),
      };
    case LOADING_IMPORT_PATIENT:
      return {
        ...state,
        loading_import_patients: true,
        import_patients_errors: null,
      };
    case DELETE_MESSAGE_PATIENT:
      let del_mes = state.messages.findIndex((x) => x.id === action.payload.id);
      return {
        ...state,
        messages: state.messages.filter((item, index) => index !== del_mes),
      };
    case IMPORT_PATIENT_SUCCESS:
      return {
        ...state,
        patient_import: action.payload.patient,
        loading_import_patients: false,
        import_patients_errors: null,
      };
    case IMPORT_PATIENT_ERRORS:
      return {
        ...state,
        loading_import_patients: false,
        import_patients_errors: action.payload,
      };
    case DELETE_PATIENT_ERROR:
      return {
        ...state,
        loading_delete_patient: false,
        delete_patient_errors: action.payload,
      };
    case LOADING_CHECK_PATIENT:
      return {
        ...state,
        loading_patient_check: true,
        check_patient_errors: null,
      };
    case CHECK_PATIENT_ERROR:
      return {
        ...state,
        loading_patient_check: true,
        check_patient_errors: action.payload,
      };
    case UPDATE_PATIENT_ERROR:
      return {
        ...state,
        loading_update_patient: false,
        update_patient_error: action.payload.errors,
      };
    case UPLOAD_PATIENT_DOCUMENT_ERRORS:
      return {
        ...state,
        loading_upload_patient_document: false,
        upload_patient_document_errors: action.payload,
      };
    case ADD_PRESCIPTION_ERROR:
      return {
        ...state,
        loading_add_prescription: false,
        add_prescription_errors: action.payload,
      };
    case DELETE_PRESCIPTION_ERROR:
      return {
        ...state,
        loading_delete_prescription: false,
        delete_prescription_errors: action.payload,
      };
    case UPDATE_PRESCIPTION_ERROR:
      return {
        ...state,
        loading_update_prescription: false,
        update_prescription_errors: action.payload,
      };
    case CHECK_PATIENT_SUCCESS:
      return {
        ...state,
        duplicate: action.payload.duplicate,
        loading_patient_check: false,
        check_patient_errors: null,
      };
    default:
      return state;
  }
}
