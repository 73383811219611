import {
    GET_ANALYTICS_ERRORS,
    LOADING_GET_ANALYTICS,
    SET_ANALYTICS
    } from '../types';
    
    const initialState = {
      appointment: [],
      sales:[],
      patient:[],
      recall:[],
      loading_analytics: false,
      analytics_errors: null,
      services_stats:[],
      dispensing_statistics:[],
      stock:[],
      total_dispensed:0,

    };
    
    export default function(state = initialState, action) {
      switch (action.type) {
        case LOADING_GET_ANALYTICS:
          return {
            ...state,
            loading_analytics: true,
            analytics_errors: null,
          };
        case GET_ANALYTICS_ERRORS:
          return {
            ...state,
            loading_update_tax: false,
            update_tax_errors: action.payload,
          };
        case SET_ANALYTICS:
          return {
            ...state,
            loading_analytics: false,
            analytics_errors: null,
            sales:action.payload.sales,
            patient:action.payload.patient,
            appointment:action.payload.appointment,
            recall:action.payload.recall,
            services_stats:action.payload.services_stats,
            dispensing_statistics:action.payload.dispensing_statistics,
            stock:action.payload.stock,
            total_dispensed:action.payload.total_dispensed,
          };

        default:
          return state;
      }
    }
    