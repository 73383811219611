import {
  LOADING_BRAND,
  SET_BRANDS,
  BRAND_ERRORS,
  ADD_BRAND,
  ADD_BRAND_ERRORS,
  LOADING_ADD_BRAND,
  LOADING_DELETE_BRAND,
  DELETE_BRAND,
  DELETE_BRAND_ERRORS,
  LOADING_UPDATE_BRAND,
  UPDATE_BRAND,
  UPDATE_BRAND_ERRORS,
} from "../types";

const initialState = {
  brands: [],
  brand: {},
  loading_brands: false,
  loading_delete_brand: false,
  delete_brand_errors: null,
  brand_errors: null,
  links: "",
  meta: 0,
  loading_add_brand: false,
  add_brand_errors: null,
  loadingUpdateBrand: false,
  updateBrandErrors: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_BRAND:
      return {
        ...state,
        loading_brands: true,
      };

    case SET_BRANDS:
      return {
        ...state,
        brands: action.payload.data,
        loading_brands: false,
        brand_errors: null,
      };
    case BRAND_ERRORS:
      return {
        ...state,
        brand_errors: action.payload,
        loading_brands: false,
      };
    case LOADING_ADD_BRAND:
      return {
        ...state,
        loading_add_brand: true,
        add_brand_errors: null,
      };

    case ADD_BRAND:
      return {
        ...state,
        brands: [action.payload, ...state.brands],
        loading_add_brand: false,
        add_brand_errors: null,
      };
    case ADD_BRAND_ERRORS:
      return {
        ...state,
        loading_add_brand: false,
        add_brand_errors: action.payload.errors,
      };

    case LOADING_UPDATE_BRAND:
      return {
        ...state,
        loadingUpdateBrand: true,
        updateBrandErrors: null,
      };

    case UPDATE_BRAND:
      let brandIndex = state.brands.findIndex(
        (x) => x.id === action.payload.id
      );
      const updatedBrands = [...state.brands];
      updatedBrands[brandIndex] = action.payload;
      return {
        ...state,
        brands: [...updatedBrands],
        loadingUpdateBrand: false,
        updateBrandErrors: null,
      };

    case UPDATE_BRAND_ERRORS:
      return {
        ...state,
        loadingUpdateBrand: false,
        updateBrandErrors: action.payload.errors,
      };
    case LOADING_DELETE_BRAND:
      return {
        ...state,
        loading_delete_brand: true,
        delete_brand_errors: null,
      };
    case DELETE_BRAND:
      let del = state.brands.findIndex((x) => x.id === action.payload.id);
      return {
        ...state,
        brands: state.brands.filter((item, index) => index !== del),
        loading_delete_brand: false,
        delete_brand_errors: null,
      };
    case DELETE_BRAND_ERRORS:
      return {
        ...state,
        loading_add_brand: false,
        add_brand_errors: action.payload,
      };
    default:
      return state;
  }
}
